@import './abstracts/colors';

.sorteo-reporte {
    &--list {

        .items-rows {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            grid-template-rows: 1fr;
            margin-bottom: 2.5rem;

            .item {
                text-align: center;

                &__number,
                &__tiempos {
                    font-size: 0.875rem;

                    &.gotSold {
                        font-weight: 600;

                        .super {
                            font-weight: 600;
                        }
                    }

                    .super {
                        vertical-align: 2px;
                        font-size: 0.75rem;
                    }
                }

                &__number {
                    ::after {
                        content: '-';
                        padding: 0 2px;
                    }
                }
            }
        }
    }
}
.tiempos-summary {
    
    &__list{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr;
        
    }
    &__total {
        background-color: $color_active;
        text-align: center;
        font-weight: 600;
        border-left: solid 2px white;

        &:nth-child(1) {
            border-left: unset;
        }
    }

}