@import './abstracts/colors';

main.Sale {
    height: 80vh;

    .sale-screen {
        background-color: $color_negro;
        color: white;

        .tpl_qtyNumber {
            font-weight: 500;
            font-size: 2.5rem;
            background-color: unset;
            border: unset;
            text-align: center;
            color: white;
            width: 100%;
        }

        .tpl_labelText {
            font-weight: 400;
            color: $color_gris-oscuro;
        }

        &__display{
            display: grid;
            grid-template-columns: 1fr 1fr;
        }

        .tiempos,
        .numero {
            display: flex;
            flex-direction: column;
            align-items: center;

            input {
                margin: 0;

                &::placeholder{
                    color: $color_gris-oscuro;
                }
                &:focus-visible,
                &:focus,
                &:active{
                    border: unset;
                    outline: unset;
                }
            }

            &__qty {
                @extend .tpl_qtyNumber;
            }

            &__label {
                @extend .tpl_labelText;

                &.active {
                    color: $color_active;
                }
            }
        }

    }

    .sale-total {
        background-color: $color_2;

        .available,
        .tiempos,
        .total {
            font-size: 0.875rem;
            font-weight: 400;

            p {
                margin: 0;
            }

            &__qty {
                font-size: 1rem;
                font-weight: 600;
            }
        }
    }

    .keypad {
        .digits {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-template-rows: 1fr 1fr 1fr 1fr;

            button {
                width: 100%;
                border: solid 1px $color_negro;
                background-color: white;
                border-radius: 0.625rem;
                padding: 10px 0;
                text-align: center;
                font-size: 10vw;
                text-transform: uppercase;
                color: $color_negro;
                font-weight: 600;
            }
        }
    }

    .actions {
        &__buttons-list {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 10vh;

            .tpl_actionsBtn {
                color: white;
                font-weight: 600;
                font-size: 2rem;
                border: unset;
            }

            .cancel {
                background-color: red;
                @extend .tpl_actionsBtn;
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
            }

            .submit {
                background-color: $color_active;
                @extend .tpl_actionsBtn;
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
            }
        }
    }
}