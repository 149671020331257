@import './abstracts/colors';

.loading-time {
    
    &__assets{
        display: flex;
        flex-direction: row;
        column-gap: 5px;
        align-items: center;
        justify-content: center;
    }
    svg {
        font-size: 2rem;
        animation: rotation 2s infinite linear;
    }

    h2 {
        margin-bottom: 0;
    }

    @keyframes rotation {
        from {
            transform: rotate(0deg);
        }

        to {
            transform: rotate(359deg);
        }
    }
}