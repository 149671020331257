@import './abstracts/colors';

/* Sorteo-review (BlackBox) */
.sorteo-review {
    background-color: $color_negro;
    color: white;

    .sorteo__dashboard {
        .sorteo-info {
            font-size: 0.813rem;

            p {
                margin: 0;
            }

            .country {
                font-weight: 600;
            }
        }

        .sorteo-statistics {
            &__sales {
                label {
                    font-size: 0.875rem;
                }

                .total__sale,
                .total__comission {
                    font-weight: 600;
                    font-size: 1.125rem;

                }
            }

            &__others {
                i {
                    color: $color_2;
                    font-size: 25.5vw;
                }
            }
        }

        .sorteo-validTo {
            p {
                font-size: 0.875rem;
            }
        }
    }
}

.metrics {
    .pInsideMetricsBox {
        margin: 0;
    }

    .metricsIcons {

        font-size: 1.250rem;
    }

    .metricsTitle {
        font-size: 0.813rem;
        font-weight: 400;
    }

    .metricsAmount {
        font-weight: 600;
    }

    &-tiempos {
        background-color: $color_3;

        i,svg {
            @extend .metricsIcons;
        }

        &__txt {
            @extend .metricsTitle;
        }

        &__amount {
            @extend .metricsAmount;
        }

        p {
            @extend .pInsideMetricsBox;
        }
    }

    &-prize {
        background-color: $color_2;

        i {
            @extend .metricsIcons;
        }

        p {
            @extend .pInsideMetricsBox;
        }

        &__txt {
            @extend .metricsTitle;
        }

        &__amount {
            @extend .metricsAmount;
        }
    }
}

.transactions {
    &-header {
        .title {
            font-weight: 600;
        }

        .action {
            font-weight: 600;
            color: $color_gris-oscuro;
        }
    }

    .transaction {
        font-size: 0.875rem;

        a{
            color: inherit;
            text-decoration: none;
        }

        &.winner {
            i {
                background-color: $color_2;
                box-shadow: unset;
                -webkit-box-shadow: unset;
                -moz-box-shadow: unset;
            }

            .transaction__info {
                .customer {
                    &__haveAnyPrize {
                        color: #00E031;
                        font-weight: 700;
                    }
                }
            }
        }

        i {
            padding: 10px;
            background-color: $color_4;
            box-shadow: inset 0px 0px 10px 0px rgba($color: $color_negro, $alpha: 0.3);
            -webkit-box-shadow: inset 0px 0px 10px 0px rgba($color: $color_negro, $alpha: 0.3);
            -moz-box-shadow: inset 0px 0px 10px 0px rgba($color: $color_negro, $alpha: 0.3);
        }

        p {
            margin: 0;
        }

        &__info {
            .infoFirstDeck {

                font-weight: 600;
            }

            .infoSecondDeck {

                font-weight: 400;
            }

            .customer {
                &__name {
                    @extend .infoFirstDeck;
                }

                &__haveAnyPrize {
                    @extend .infoSecondDeck;
                }
            }

            .tiempo {
                text-align: right;

                &__ref {
                    @extend .infoFirstDeck;
                }

                &__salesAmount {
                    @extend .infoSecondDeck;
                }
            }
        }
    }

}