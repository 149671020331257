@import './abstracts/colors';

.login {

    input[type="text"],
    input[type="password"] {
        &:focus {
            background-color: #F6F5F6;
            border-color: #D7D9E0;
            color: $color_gris-oscuro;
            box-shadow: 0 0 0 0.25rem rgba($color: $color_2, $alpha: 0.25);
        }
    }

    button {
        font-weight: 600;
    }
}