@import './abstracts/colors';

.sorteos-list{
    .sorteo {
        border-bottom: solid 1px rgba(0, 0, 0, 0.1);

        &-item,
        &-data{
            p{
                margin-bottom: 0;
            }

            &__date,
            &__hour {
                font-size: 0.75rem;
            }

            &__date{
                .openOrClose{
                        font-size: 0.625rem;
                        font-weight: 600;
                        border: solid 1px rgba($color: $color_negro, $alpha: 0.1);
                        background-color: rgba($color: $color_gris-oscuro, $alpha: 0.10);
                        padding: 2px 4px;
                        border-radius: 4px;
                        margin-left: 4px;
                        color: $color_negro;
                }
            }

        }

        &-data{
            text-align: right;
        }

    }
}