@import './abstracts/colors';

.sideHeader{
    background-color: white;
    box-shadow: 0px 6px 5px 0px rgba($color: $color_negro, $alpha: 0.05);
    -webkit-box-shadow: 0px 6px 5px 0px rgba($color: $color_negro, $alpha: 0.05);
    -moz-box-shadow: 0px 6px 5px 0px rgba($color: $color_negro, $alpha: 0.05);

    .row{
        border-bottom: solid 1px rgba($color: $color_negro, $alpha: 0.1);
    }

    &-content{
        display: flex;
        flex-direction: row;
        column-gap: 5px;
        align-items: center;

        &__navbar{
            font-size: 1.5rem;

            a,
            span{
                i{
                    display: flex;
                    align-items: center;
                }
            }
        }
        &__title{
            font-size: 1rem;
            margin-bottom: 0;
        }
    }
}