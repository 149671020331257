@import './abstracts/colors';

header.ProfileHeader {
    .profile {
        &__icon {
            display: flex;
            justify-content: center;
            align-items: center;
            min-width: 40px;
            background-color: $color_2;
            color: $color_1;
            border: solid 1px $color_negro;
            padding: 10px;
            border-radius: 50%;
        }

        &-info {
            p {
                margin: 0;
            }

            &__name {
                font-weight: 600;
                font-size: 1rem;
                text-transform: capitalize;
            }

            &__phone {
                font-size: 0.813rem;
                font-weight: 400;
            }
        }
    }

    .notifications {
        &__icon {
            i {
                font-size: 18px;
                padding: 10px;
            }
        }
    }

    .sideNav{
        transition: 0.5s;
        height: 100%;
        width: 0;
        position: fixed;
        z-index: 1040;
        top: 0;
        left: -100%;
        background-color: $color_gris-oscuro;
        padding: 0;
        
        &.open{
            padding: 10px;
        }

        .header{
            color: white;

            &__name{
                text-transform: capitalize;
                margin-bottom: 0;
            }
            &__closeIcon{
                button{
                    color: white;
                    font-size: 1.5rem;
                    padding: 0.1875rem 0.375rem;
                }
            }
        }

        hr{
            margin: 0.5rem 0 1rem 0;
            background-color: white;
            opacity: .5;
        }

        .navbar{
            ul{
                a{
                    li{
                        display: flex;
                        align-items: center;
                        column-gap: 6px;
                    }
                }

                li{
                    margin: 0.5rem 0;
                }
                li, a{
                    color: white;
                    text-decoration: none;
                }
                .signout{
                    margin-top: 1rem;
                    text-decoration-line:underline;
                    text-decoration-color: rgba($color: white, $alpha: 0.5);
                }
            }
        }
    }
}