// Global Theme colors
$color_1: #000000;
$color_2: #C0DEDB;
$color_3: #E6DFF1;
$color_4: #F1EDE9;
$color_gris-oscuro: #707070;

// Specifics
$color_bg: #FDFCFD;
$color_negro: $color_1;
$color_active:#00E031;