@import './abstracts/colors';

.sale-ticket {
    .ticket {
        background-color: $color_2;

        &__title {
            text-align: center;
            font-size: 1.125rem;
        }

        &-data {
            &__info {
                font-size: 0.875rem;

                p {
                    margin-bottom: 0;

                    .data {
                        font-weight: 600;
                    }
                }
            }
        }
    }

    .ticket-data {
        &__saleNumbers {
            .saleNumbers-title{
                font-size: 14px;
            }
            .number {
                &-item {
                    display: flex;
                    flex-direction: row;
                    column-gap: 0.625rem;
                    border: solid;
                    border-radius: 8px;
                    padding: 5px;
                    justify-content: center;
                    align-items: center;

                    .tiempos,
                    .number {
                        font-weight: 600;

                        sub {
                            vertical-align: super;
                        }
                    }

                    p {
                        margin-bottom: 0;
                    }
                }
            }
        }
        &__info{
            .sellerName{
                text-transform: capitalize;
            }
        }
    }

    .finish-bar {
        .summary {
            font-size: 1.125rem;
            font-weight: 600;

            .available,
            .tiempos,
            .total {
                p {
                    margin-bottom: 0;
                }

                &__label {
                    font-size: 0.625rem;
                    font-weight: 400;
                }
            }
        }

        .success-actions {
            &__btnList {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                column-gap: 5px;

                button {
                    border: unset;
                    background-color: unset;
                    display: flex;
                    flex-direction: row;
                    column-gap: 4px;
                    align-items: center;
                    
                    .centerIconsButton{
                        display: flex;
                        align-items: center;
                    }

                    &.delete {
                        i {
                            color: red;
                            @extend .centerIconsButton;
                        }
                    }

                    &.print {
                        i {
                            color: blue;
                            @extend .centerIconsButton;
                        }
                    }

                    &.share {
                        i {
                            color: black;
                            @extend .centerIconsButton;
                        }
                    }

                    span {
                        font-size: 0.75rem;
                    }
                }
            }
        }

        &__closeBtn {
            button {
                font-weight: 600;
            }
        }
    }
}