@import './abstracts/colors';

footer.site-footer {
    .row {
        background-color: white;
        box-shadow: 0px -7px 7px 0px rgba($color: $color_negro, $alpha: 0.1);
        -webkit-box-shadow: 0px -7px 7px 0px rgba($color: $color_negro, $alpha: 0.1);
        -moz-box-shadow: 0px -7px 7px 0px rgba($color: $color_negro, $alpha: 0.1);
    }

    nav{
        justify-content: space-evenly;

        a{
            color: $color_negro;

            i{
                font-size: 1.5rem;

            }

            &.sale{
                background-color: $color_negro;
                color: white;
                min-height: 45px;
                min-width: 45px;
                border-radius: 50%;
            }

            &.sale,
            &.sale i{
                display: flex;
                align-items: center;
            }
        }
    }
}