@import './abstracts/colors';

.sale-preview {
    .items-sold {
            background-color: rgba($color: $color_gris-oscuro, $alpha: 0.1);
            padding-top: 2px;
            min-height: 40vh;
    
            .item-sold {
                &__number {
                    display: flex;
                    justify-content: end;
                    column-gap: 1rem;
                    padding: 8px 10px;
                    background-color: white;
    
                    .tpl_txtStyles {
                        font-weight: 600;
                        font-size: 1.25rem;
    
                        .superScript {
                            vertical-align: super;
                            font-size: 0.625rem
                        }
                    }
    
                    .tiempos {
                        @extend .tpl_txtStyles;
                    }
    
                    .item-number {
                        @extend .tpl_txtStyles;
                    }
    
                }
    
                &__amount {
                    background-color: $color_active;
                    color: white;
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
    
                &__delete {
                    background-color: red;
                    border: unset;
                    color: white;
                    text-align: center;
                }
            }
        }

    .finish-bar {
        
    }
}