@import './abstracts/colors';

.SaleSummary {
    font-size: 1.125rem;
    font-weight: 600;

    .available,
    .tiempos,
    .total {
        p {
            margin-bottom: 0;
        }

        &__label {
            font-size: 0.625rem;
            font-weight: 400;
        }

        &.highlight{
            background-color: $color_2;
        }
    }
}